document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    $("[data-submit]").on("click", function (e) {
      e.preventDefault();

      //TODO PRZYWRÓCIĆ KIEDY BELKA WBO ZOSTANIE USUNIETA

      // $("#backToHomepage").style.display = 'none';
      const component = $(this).closest('.newsletter');
      const emailField = component.find('[data-placeholder]')

      if (emailField.val().length > 0) {
        var isCorrect = true;

        if (isCorrect && !validEmail(emailField)) {
          isCorrect = false;

          Swal.fire({
            icon: "error",
            title: "Wprowadź poprawny adres e-mail",
          });
          changeHTMLTag("swal2-title", "h1");

          $(this).parent().addClass("error");
        } else {
          $(this).parent().removeClass("error");

          $.ajax({
            method: "GET",
            // PROD url
            url: "https://www.wroclaw.pl/beta2/proxy/wroclaw-pl-strona-glowna/get-html-parts?type=newsletter_form",

            // DEV url
            // url: 'get-html-parts?type=newsletter_form',

            beforeSend: function () {
              $(".ajaxNewsletterForm").addClass("loading");
            },
          }).done((data, status) => {
            $(".ajaxNewsletterForm").removeClass("loading");

            if (status === "success") {
              let ajaxNewsletterForm =
                document.getElementsByClassName("ajaxNewsletterForm");
              ajaxNewsletterForm.item(0).insertAdjacentHTML("beforeend", data);

              $("[data-content]").addClass("expanded");

              trapFocus("#newsletter");
            }

            // Zamknij okno
            $("[data-close]").on("click", function (e) {
              e.preventDefault();
              $("[data-content]").removeClass("expanded");
              $(".newsletterForm").remove();
              $("#backToHomepage").style.display = 'flex';
            });

            $("[data-close]").on("keydown", function (e) {
              if (e.keyCode === 13) {
                e.preventDefault();
                $("[data-content]").removeClass("expanded");
                $(".newsletterForm").remove();
                $("#backToHomepage").style.display = 'flex';
              }
            });

            // wyswietl wiecej informacji
            $("[data-more-btn]").on("click", function () {
              e.preventDefault();
              dropdownContent($(this));
            });

            $("[data-more-btn]").on("keydown", function (e) {
              if (e.keyCode === 13) {
                e.preventDefault();
                dropdownContent($(this));
              }
            });

            $("[data-show-btn]").on("click", function () {
              e.preventDefault();
              $(this).parent().toggleClass("showInfo");
            });

            $("[data-show-btn]").on("keydown", function (e) {
              if (e.keyCode === 13) {
                e.preventDefault();
                $(this).parent().toggleClass("showInfo");
              }
            });

            // zaznaczenie input-checkbox za pomoca klawiatury
            $("input:checkbox").keypress(function (e) {
              if ((e.keyCode ? e.keyCode : e.which) == 13) {
                e.preventDefault();
                $(this).trigger("click");
              }
            });

            // Zapisz sie i wyslij
            $("[data-send-newsletter]").on("click", function (e) {
              e.preventDefault();

              $(".newsletterForm .newsletter").addClass("loading");
              if (
                  $("#iLegals").is(":checked") &&
                  $("#iLegals2").is(":checked") === true
              ) {
                $("[data-checkbox]").parent().removeClass("error");

                var rodo = "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Agencję Rozwoju Aglomeracji Wrocławskiej S. A. z siedzibą we Wrocławiu, przy placu Solnym 14, 50-062 Wrocław, KRS: 0000248319 (dalej: Spółka) w celu wysyłki newslettera Spółki, obejmującego ofertę handlową produktów lub usług Spółki oraz jej partnerów."
                var subscribe = "Wyrażam zgodę na otrzymywanie drogą elektroniczną, na podany adres poczty elektronicznej, informacji handlowej od Agencji Rozwoju Aglomeracji Wrocławskiej S. A. z siedzibą we Wrocławiu, przy placu Solnym 14, 50-062 Wrocław, KRS: 0000248319 oraz jej partnerów, a także na użycie do tego celu telekomunikacyjnych urządzeń końcowych."

                $.ajax({
                  method: "POST",
                  dataType: "json",
                  url: "https://assets.mailerlite.com/jsonp/724038/forms/111063013377705037/subscribe",
                  data: {
                    fields:{
                      email : $(".newsletter input[name=sEmail]").val(),
                      rodo: rodo,
                      subscribe: subscribe
                    }
                  },
                }).done(function (response) {
                  emailField.val("");
                  $("[data-content]").removeClass("expanded");
                  $(".newsletterForm").remove();

                  var isSuccess = false;

                  $.each(response, function (key, value) {
                    if (key == "success") isSuccess = value;
                  });

                  if (isSuccess) {
                    $(".newsletterForm .newsletter").removeClass("loading");

                    Swal.fire({
                      icon: "success",
                      title: "Już prawie! Potwierdź zapis",
                      text: "Ważne: Na wskazany adres wysłaliśmy wiadomość z prośbą o zatwierdzenie zapisu na newsletter.  Znajduje się w nim link, który należy kliknąć w celu potwierdzenia swojej decyzji.",
                    });
                    changeHTMLTag("swal2-title", "h1");

                    const el = document.getElementsByClassName("newsletter");
                    const x = el.focus();
                  } else {
                    $(".newsletterForm .newsletter").removeClass("loading");
                    Swal.fire({
                      icon: "error",
                      title: "Wystąpił błąd, spróbuj ponownie",
                    });
                    changeHTMLTag("swal2-title", "h1");
                  }
                });
              } else {
                $(".newsletterForm .newsletter").removeClass("loading");
                Swal.fire({
                  icon: "error",
                  title: "Uzupełnij wymagane pola.",
                });
                changeHTMLTag("swal2-title", "h1");

                $("#iLegals, #iLegals2").each(function() {
                  const $parent = $(this).parent();
                  if (!$(this).is(":checked")) {
                    $parent.addClass("error");
                  } else {
                    $parent.removeClass("error");
                  }
                });
              }
            });
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Wprowadź adres e-mail",
        });
        changeHTMLTag("swal2-title", "h1");

        $(this).parent().addClass("error");
      }

      var data = emailField.val();
    });

    $(document).on("keydown", function (e) {
      if (e.keyCode === 27) {
        $("[data-content]").removeClass("expanded");
        $(".newsletterForm").remove();
      }
    });
  });

  function validationEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  function validEmail($obj) {
    if ($($obj).val().length > 0 && validationEmail($($obj).val())) {
      return true;
    }
  }

  // unikalna klasa dla buttonow 'Zapisz sie' w newsletterze - zroznicowanie desktop/mobile
  $(function () {
    var newsletterFirstBtn = $("[data-newsletter-first-btn]");
    var newsletterSendBtn = $("[data-newsletter-send-btn]");

    if ($(window).width() > 767) {
      // pierwszy button
      newsletterFirstBtn.addClass("newsletterFirstBtn_desktop");
      newsletterFirstBtn.removeClass("newsletterFirstBtn_mobile");

      // drugi button w popupie
      newsletterSendBtn.addClass("newsletterSendBtn_desktop");
      newsletterSendBtn.removeClass("newsletterSendBtn_mobile");
    } else {
      // pierwszy button
      newsletterFirstBtn.addClass("newsletterFirstBtn_mobile");
      newsletterFirstBtn.removeClass("newsletterFirstBtn_desktop");

      // drugi button w popupie
      newsletterSendBtn.addClass("newsletterSendBtn_mobile");
      newsletterSendBtn.removeClass("newsletterSendBtn_desktop");
    }
  });
});
